<template>
  <base-view title="Claves presupuestales" icon="key">
    <b-row v-if="hastList">
      <b-col cols="12">
        <div class="card card-header-actions mb-4">
          <div class="card-header">
            Claves presupuestales

            <button
              class="btn btn-success btn-sm ml-auto"
              :disabled="disableCreate"
              @click="createClave"
            >
              Crear
            </button>
          </div>

          <div class="card-body">
            <check-authorization :requiresAuthorizations="['listar claves presupuestales']">
              <claves-presupuestales-table
                :create-new-clave="createNewClave"
                @on-creating="creating"
              />
            </check-authorization>
          </div>
        </div>
      </b-col>
    </b-row>

    <loading v-else></loading>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import ClavesPresupuestalesTable from '@/components/ClavesPresupuestales/ClavesPresupuestalesTable'

export default {
  name: 'ClavesPresupuestales',

  components: {
    ClavesPresupuestalesTable
  },

  async created () {
    await this.$store.dispatch('clavesPresupuestalesModule/getAll')
  },

  computed: {
    ...mapGetters('clavesPresupuestalesModule', ['hastList', 'getList'])
  },

  data () {
    return {
      createNewClave: null,
      disableCreate: false
    }
  },

  methods: {
    createClave () {
      const claves = this.$store.state.clavesPresupuestalesModule.list.data.data
      const nuevaClave = {
        ai: '',
        at: '',
        ee: '',
        ep: '',
        es: '',
        fe: '',
        fi: '',
        fo: '',
        fu: '',
        id: claves[claves.length - 1].id + 1,
        la: '',
        md: '',
        mu: '',
        oe: '',
        pp: '',
        py: '',
        ra: '',
        se: '',
        sf: '',
        spnf: '',
        ss: '',
        tf: '',
        tg: '',
        un: '',
        periodo_fiscal_id: 1,
        periodo_fiscal: {
          id: '',
          nombre: ''
        },
        isNew: true
      }
      claves.push(nuevaClave)

      this.createNewClave = nuevaClave
    },

    creating (isCreating) {
      this.disableCreate = isCreating
    }
  }
}
</script>
