<template>
  <div>
    <!-- <pre>
      {{ $data.editingItem }}
    </pre> -->
    <b-table
      :fields="fields"
      :items="items"
      show-empty
      responsive
      striped
      bordered
      hover
      small
      :class="{ 'my-table': isEditing }"
    >
      <!-- TOP HEADS -->
      <template #thead-top>
        <b-tr>
          <b-th colspan="7" class="text-center text-sm">Administrativa</b-th>
          <b-th colspan="3" class="text-center text-sm">Funcional</b-th>
          <b-th colspan="4" class="text-center text-sm">Alineación al ped</b-th>
          <b-th colspan="5" class="text-center text-sm">Programática</b-th>
          <b-th colspan="1" class="text-center text-sm">Geográfica</b-th>
          <b-th colspan="3" class="text-center text-sm">Económica</b-th>
          <b-th colspan="1" class="text-center text-sm">Periodo fiscal</b-th>
          <b-th colspan="1" class="text-center text-sm">Acciones</b-th>
        </b-tr>
      </template>

      <!-- HEAD -->
      <template #head(sp)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(spnf)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(se)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(sbe)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ep)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ra)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(un)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(fi)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(fu)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(sf)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ms)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(oe)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(es)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(la)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ai)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(mpp)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(pp)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(pc)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ac)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(eg)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(tg)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(ff)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(parti)="column">
        <div class="text-primary text-center text-sm w-100">
          {{ column.label.toUpperCase() }}
        </div>
      </template>
      <template #head(periodo_fiscal)>
        <div class="text-primary text-center w-100"></div>
      </template>

      <!-- CELLS -->
      <template #cell(sp)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.sp"
          ref="sp"
          type="text"
          size="sm"
          class="text-center p-0 m-0"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.sp }}</div>
      </template>

      <template #cell(spnf)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.spnf"
          ref="spnf"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.spnf }}</div>
      </template>

      <template #cell(se)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.se"
          ref="se"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.se }}</div>
      </template>

      <template #cell(sbe)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.sbe"
          ref="sbe"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.sbe }}</div>
      </template>

      <template #cell(ep)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ep"
          ref="ep"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ep }}</div>
      </template>

      <template #cell(ra)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ra"
          ref="ra"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ra }}</div>
      </template>

      <template #cell(un)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.un"
          ref="un"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.un }}</div>
      </template>

      <template #cell(fi)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.fi"
          ref="fi"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.fi }}</div>
      </template>

      <template #cell(fu)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.fu"
          ref="fu"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.fu }}</div>
      </template>

      <template #cell(sf)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.sf"
          ref="sf"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.sf }}</div>
      </template>

      <template #cell(ms)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ms"
          ref="ms"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ms }}</div>
      </template>

      <template #cell(oe)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.oe"
          ref="oe"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.oe }}</div>
      </template>

      <template #cell(es)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.es"
          ref="es"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.es }}</div>
      </template>

      <template #cell(la)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.la"
          ref="la"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.la }}</div>
      </template>

      <template #cell(ai)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ai"
          ref="ai"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ai }}</div>
      </template>

      <template #cell(mpp)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.mpp"
          ref="mpp"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.mpp }}</div>
      </template>

      <template #cell(pp)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.pp"
          ref="pp"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.pp }}</div>
      </template>

      <template #cell(pc)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.pc"
          ref="pc"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.pc }}</div>
      </template>

      <template #cell(ac)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ac"
          ref="ac"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ac }}</div>
      </template>

      <template #cell(eg)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.eg"
          ref="eg"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.eg }}</div>
      </template>

      <template #cell(tg)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.tg"
          ref="tg"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.tg }}</div>
      </template>

      <template #cell(ff)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.ff"
          ref="ff"
          type="text"
          size="sm"
          class="text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.ff }}</div>
      </template>
      <template #cell(parti)="row">
        <b-form-input
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.parti"
          ref="parti"
          type="text"
          size="sm"
          class="form-control text-center"
          @keyup.enter="update"
        ></b-form-input>
        <div v-else>{{ row.item.parti }}</div>
      </template>

      <template #cell(periodo_fiscal)="row">
        <x-select
          v-if="row.item.id === editingItem.id"
          v-model="editingItem.periodo_fiscal.id"
          ref="periodo_fiscal"
          dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
          getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
          :formatListWith="['id', 'nombre']"
          no-prepend
          no-validate
          size="sm"
          classes="m-0 p-0"
        ></x-select>
        <div v-else>
          {{ row.item.periodo_fiscal.nombre }}
        </div>
      </template>

      <template #cell(acciones)="row">
        <div class="d-flex flex-column align-items-center" style="font-size: 1rem">
          <div v-if="row.item.id === editingItem.id" class="d-flex">
            <b-button
              v-if="isEditing"
              class="mr-2 mb-2 mb-sm-0"
              size="sm"
              @click="toggleEditMode(null)"
              variant="dark"
            >
              Cancelar
            </b-button>
            <b-button
              v-if="isEditing"
              class="btn-outline-success mr-2 mb-2 mb-sm-0"
              size="sm"
              variant="white"
              @click="update"
            >
              Guardar
            </b-button>
          </div>
          <u-d-controls
            v-else
            editPath="clavesPresupuestalesModule/getById"
            deletePath="clavesPresupuestalesModule/deleteById"
            :id="row.item.id"
            :editPermissions="['editar claves presupuestales']"
            :deletePermissions="['eliminar claves presupuestales']"
            hide-edit-action
          >
            <template #edit>
              <b-button
                v-if="row.item.id !== editingItem.id"
                class="btn-outline-primary mr-2 mb-2 mb-sm-0"
                size="sm"
                variant="white"
                @click="toggleEditMode(row.item)"
              >
                Editar
              </b-button>
            </template>
          </u-d-controls>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import XSelect from '@/components/Shared/XSelect'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ClavesPresupuestalesTable',

  components: {
    XSelect
  },

  data () {
    return {
      fields: [],
      items: [],

      editingItem: {}
    }
  },

  props: {
    createNewClave: {
      type: Object,
      value: null
    },

    disableCreate: {
      type: Boolean,
      value: false
    }
  },

  computed: {
    ...mapGetters('clavesPresupuestalesModule', ['hastList', 'getList']),

    isEditing () {
      return !_.isEmpty(this.editingItem)
    }
  },

  watch: {
    //
    // En cada actualización de items.
    // vuelve a hacer el proceso para pintar
    // las cabeceras personalizadas y los items.
    getList: {
      deep: true,
      immediate: true,
      handler (newItems) {
        if (!this.hastList) return

        this.getKeys(newItems)
        this.getValues(newItems)
      }
    },

    createNewClave: {
      handler (newItem, o) {
        if (newItem !== o) {
          this.$emit('on-creating', true)
          this.toggleEditMode(newItem)
        }
      }
    }
  },

  methods: {
    /**
     * Obtiene las "llaves" de un objeto.
     *
     * Obtiene las llaves del objeto dado como parámetro,
     * para hacer una cabecera personalizada para la tabla de Bootstrap.
     */
    getKeys (data) {
      const row = _.clone(data[0])

      delete row.id
      const myrow = this.removeFields(row)

      //
      // Añade la cabecera sin los timestamps
      this.fields = []
      this.fields = _.keys(myrow)
      this.fields.push({ key: 'periodo_fiscal', label: '' })
      this.fields.push({ key: 'acciones', label: '' })
    },

    /**
     * Obtiene los items de un objeto.
     *
     * Obtiene los items del objeto dado como parámetro.
     *
     * Va de la mano con la información que se presenta en la cabecera.
     */
    getValues (data) {
      this.items = []

      for (const item of data) {
        const myitem = this.removeFields(item)

        //
        // Añade el nuevo elemento sin los timestamps
        this.items.push(myitem)

        //
        // Obtiene únicamente los valores de la respuesta
        // const values = _.values(myitem)
      }
    },

    removeFields (item) {
      const newItem = _.clone(item)

      delete newItem.created_at
      delete newItem.updated_at
      delete newItem.periodo_fiscal_id

      return newItem
    },

    getValuesFromRow (items) {
      const nombre = items.periodo_fiscal.nombre

      const values = _.values(items)
      values.push(nombre)

      return [values]
    },

    /**
     * Actualiza los datos de una clave presupuestal.
     */
    async update () {
      const payload = { ...this.editingItem, periodo_fiscal_id: this.editingItem.periodo_fiscal.id }

      const getval = this.editingItem.isNew
        ? await this.$store.dispatch('clavesPresupuestalesModule/create', payload)
        : await this.$store.dispatch('clavesPresupuestalesModule/updateById', {
          id: payload.id,
          payload
        })

      this.$notify(getval, 'Actualización de clave presupuestal')

      if (getval.error) return

      this.toggleEditMode(null)
    },

    toggleEditMode (item = null) {
      if (!item) {
        this.editingItem = {}
        this.$emit('on-creating', false)
        this.$store.dispatch('clavesPresupuestalesModule/getAll')
        return
      }

      this.editingItem = item

      this.$delay(300).then(() => this.$refs.sp.focus())
    },

    emitUpdated (value) {
      this.$emit('on-updated', value)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .table > tbody > tr {
  text-align: center;
}

.my-table {
  /deep/ .table > tbody > tr > td {
    min-width: 70px;
    font-size: 0.9rem;
  }
}
</style>
